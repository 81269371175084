import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { v4 as uuid } from 'uuid';

const CALLBACK_BY_LISTENER = {};
function acceptActionWhenReady(acceptAction) {
  const listeners = Object.values(CALLBACK_BY_LISTENER);
  if (listeners.length === 0) {
    acceptAction();
  } else {
    listeners.forEach((listener) => {
      listener(acceptAction);
    });
  }
}
function addListener(callback) {
  const id = uuid();
  CALLBACK_BY_LISTENER[id] = callback;
  return id;
}
function removeListener(id) {
  delete CALLBACK_BY_LISTENER[id];
}

/**
 * @param shouldWarn if true, the component will be subscribed to the router push event before navigation.
 * @returns a push function (like next's useRouter().push) and an acceptAction function that the subscribing function can call when ready to navigate.
 */
export function useAppRouter(shouldWarn) {
  const [{ acceptAction }, setAcceptAction] = useState({ acceptAction: undefined });
  const { push } = useRouter();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (shouldWarn) {
      const id = addListener((acceptAction) => {
        setAcceptAction({ acceptAction });
      });
      return () => {
        removeListener(id);
      };
    }
  }, [shouldWarn]);

  return {
    push: (route) => {
      acceptActionWhenReady(() => {
        push(route);
      });
    },
    acceptAction,
  };
}

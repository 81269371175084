// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  error: {
    notFound: '/not-found',
  },
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login/`,
      register: `${ROOTS.AUTH}/jwt/register/`,
    },
    selectInstance: '/select-instance/',
  },
  // DASHBOARD
  dashboard: {
    root: '/accession-list/',
    accessionList: `/accession-list/`,
    sharedAccessions: `/shared-accessions/`,
    imageList: `/image-list/`,
    modelReview: `/model-review/`,
    laboratoryList: `/laboratory-list/`,
    settings: `/settings/`,
    stainList: `/stain-list/`,
    stainRequests: `/stain-requests/`,
    templateList: `/template-list/`,
    interfaceTracking: `/interface-tracking/`,
    billing: `/billing`,
    billingSettings: `/billing-settings/`,
    caseList: `/clinical-accessions/`,
    pendingList: `/pending-list/`,
    abnormalList: `/abnormal-list/`,
    clinicalReports: `/clinical-reports/`,
    clinicalSettings: `/clinical-settings/`,

    administration: {
      root: `/clients/`,
      clients: `/clients/`,
      insurance: `/insurance-list/`,
      labReports: `/lab-reports/`,
    },
  },
};

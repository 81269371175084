import { createSvgIcon } from '@mui/material/utils';

export const TabIcon = createSvgIcon(
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.341 14.325L7.35768 11.3333L19.166 11.3333V9.66667L7.35768 9.66667L10.3493 6.675L9.16602 5.5L4.16602 10.5L9.16602 15.5L10.341 14.325ZM3.33268 15.5L3.33268 5.5H1.66602V15.5H3.33268Z"
      fill="white"
    />
  </svg>
);

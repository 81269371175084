// @mui
import { styled } from '@mui/material/styles';

import ListItemButton from '@mui/material/ListItemButton';

// ----------------------------------------------------------------------

export const StyledItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }) => {
  const activeStyles = {
    root: {
      color: '#ffffff',
      backgroundColor: '#414141',
      '&:hover': {
        // backgroundColor: alpha(theme.palette.primary.main, 0.16),
      },
    },
    sub: {
      // color: theme.palette.text.primary,
      backgroundColor: 'transparent',
      '&:hover': {
        // backgroundColor: theme.palette.action.hover,
      },
    },
  };

  return {
    // Root item
    // padding: config.itemPadding,
    // marginBottom: config.itemGap,
    // borderRadius: config.itemRadius,
    // minHeight: config.itemRootHeight,
    color: '#ffffff',

    // Active root item
    ...(active && {
      ...activeStyles.root,
    }),
  };
});

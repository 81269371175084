import { createSvgIcon } from '@mui/material/utils';

export const SettingsIcon = createSvgIcon(
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9504 11.2838C15.9838 11.0338 16.0004 10.7755 16.0004 10.5005C16.0004 10.2338 15.9837 9.96715 15.9421 9.71715L17.6338 8.40049C17.7838 8.28382 17.8254 8.05882 17.7338 7.89215L16.1338 5.12549C16.0338 4.94215 15.8254 4.88382 15.6421 4.94215L13.6504 5.74215C13.2337 5.42549 12.7921 5.15882 12.3004 4.95882L12.0004 2.84215C11.9671 2.64215 11.8004 2.50049 11.6004 2.50049H8.40042C8.20042 2.50049 8.04208 2.64215 8.00875 2.84215L7.70875 4.95882C7.21708 5.15882 6.76708 5.43382 6.35875 5.74215L4.36708 4.94215C4.18375 4.87549 3.97542 4.94215 3.87542 5.12549L2.28375 7.89215C2.18375 8.06715 2.21708 8.28382 2.38375 8.40049L4.07542 9.71715C4.03375 9.96715 4.00042 10.2422 4.00042 10.5005C4.00042 10.7588 4.01708 11.0338 4.05875 11.2838L2.36708 12.6005C2.21708 12.7172 2.17542 12.9422 2.26708 13.1088L3.86708 15.8755C3.96708 16.0588 4.17542 16.1172 4.35875 16.0588L6.35042 15.2588C6.76708 15.5755 7.20875 15.8422 7.70042 16.0422L8.00042 18.1588C8.04208 18.3588 8.20042 18.5005 8.40042 18.5005H11.6004C11.8004 18.5005 11.9671 18.3588 11.9921 18.1588L12.2921 16.0422C12.7838 15.8422 13.2338 15.5755 13.6421 15.2588L15.6338 16.0588C15.8171 16.1255 16.0254 16.0588 16.1254 15.8755L17.7254 13.1088C17.8254 12.9255 17.7838 12.7172 17.6254 12.6005L15.9504 11.2838ZM10.0004 13.5005C8.35042 13.5005 7.00042 12.1505 7.00042 10.5005C7.00042 8.85049 8.35042 7.50049 10.0004 7.50049C11.6504 7.50049 13.0004 8.85049 13.0004 10.5005C13.0004 12.1505 11.6504 13.5005 10.0004 13.5005Z"
      fill="white"
    />
  </svg>,
  'ChartSquareBar'
);

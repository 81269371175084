import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '4px',
    fontSize: '10px',
    letterSpacing: '-0.2px',
    lineHeight: '140%',
    backgroundColor: '#151926',
    marginLeft: '26px !important',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#151926',
  },
}));

export default StyledTooltip;

import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { useResponsive } from 'src/hooks/use-responsive';

import { useSettingsContext } from 'src/components/settings';
import StyledTooltip from 'src/components/tooltip';
import { TabIcon } from '../../assets/icons/tab';

export default function NavToggleButton({ sx, isMini, ...other }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const theme = useTheme();

  const settings = useSettingsContext();

  return (
    <StyledTooltip
      title={`${isMini ? "Show" : "Hide"} sidebar`}
      placement="right"
      arrow
      disableHoverListener={!isMini}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <Button
        fullWidth
        startIcon={<TabIcon />}
        onClick={() => {
          settings.onUpdate(
            'themeLayout',
            settings.themeLayout === 'vertical' ? 'mini' : 'vertical'
          );
          handleClose();
        }}
        sx={{
          px: 3,
          py: 1.5,
          color: '#ffffff',
          justifyContent: 'flex-start',
          lineHeight: '150%',

          '&:hover': {
            bgcolor: theme.palette.action.hoverOpacity,
            borderRadius: 0,
          },
          '& .MuiButton-startIcon': {
            ml: 0,
            mr: isMini ? 0 : 1,
            transform: isMini ? 'scale(-1)' : 'scale(1)',

          },

          ...sx,
        }}
        {...other}
      >
        <span
          style={{
            visibility: isMini ? 'hidden' : 'visible',
            flexShrink: 0,
            overflow: isMini ? 'hidden' : 'inset',
            ...(isMini && { fontSize: '0.1px' }),
          }}
        >
          Hide sidebar
        </span>
      </Button>
    </StyledTooltip>
  );
}

NavToggleButton.propTypes = {
  sx: PropTypes.object,
  isMini: PropTypes.bool,
};

import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';

import Link from '@mui/material/Link';
import { RouterLink } from 'src/routes/components';
import ListItemText from '@mui/material/ListItemText';

import { useAppRouter } from 'src/hooks/useNavigationEvent';
import { useActiveLink } from 'src/routes/hooks/use-active-link';
// routes
import { paths } from 'src/routes/paths';

import StyledTooltip from 'src/components/tooltip';
import { SettingsIcon } from '../../assets/icons/settings';

import { StyledItem } from './styles';

export default function NavUpgrade({ isMini, isAccessionPage }) {
  const settingsPath = paths.dashboard.settings;
  const { push } = useAppRouter();

  const active = useActiveLink(settingsPath, false);

  return (
    <Stack>
      <StyledTooltip title="Settings" placement="right" arrow disableHoverListener={!isMini}>
        <Link
          {...(!isAccessionPage ? { href: '/settings' } : {})}
          component={isAccessionPage ? 'button' : RouterLink}
          onClick={() => isAccessionPage && push(settingsPath)}
          underline="none"
          color="inherit"
        >
          <StyledItem disableGutters sx={{ px: 3, py: 1.5 }} active={active}>
            <SettingsIcon
              sx={{
                color: '#ffffff',
                width: 20,
                height: 20,
                mr: isMini ? 0 : 1,
              }}
            />
            <ListItemText
              primary="Settings"
              primaryTypographyProps={{
                noWrap: true,
                typography: 'body2',
                textTransform: 'capitalize',
                fontWeight: 'bold',
                letterSpacing: '-0.574px',
                lineHeight: '150%',
                visibility: isMini ? 'hidden' : 'visible',
              }}
            />
          </StyledItem>
        </Link>
      </StyledTooltip>
    </Stack>
  );
}

NavUpgrade.propTypes = {
  isMini: PropTypes.bool,
  isAccessionPage: PropTypes.bool,
};
